import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#przeziebienie"> &gt; Poradnik(Przeziębienie)</a>
            <a
              href="/poradnik/przeziebienie/jak_uchronic_sie_przed_przeziebieniem/"
              className="sel"
            >
              {" "}
              &gt; Jak uchronić się przed przeziębieniem?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/88688757.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Jak uchronić się przed przeziębieniem?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Jesienny chłód, zwiększona wilgotność powietrza zimą,
                    wiosenne przesilenie, zimny letni wiatr – przez cały rok
                    jesteśmy narażeni na czynniki, które osłabiają układ
                    odpornościowy, pogarszają nasz komfort cieplny i powodują
                    przedostawanie się wirusów do organizmu. Można tego uniknąć
                    stosując kilka łatwych rozwiązań.&nbsp;
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Badania lekarskie dowiodły, że istnieje ponad 200 często
                    występujących wirusów grypy i{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębienia
                    </a>
                    . Jednak wciąż pojawiają się nowe, a te rozpoznane mutują.
                    Dlatego organizm należy dobrze przygotować, aby nauczył się
                    zwalczać każdego "napastnika". Ochronę można zapewnić
                    stosując się do kilku zasad: ubrać dopasowany do pogody
                    strój, odpowiednio zbilansować dietę, uprawiać sporty, a
                    także pić duże ilości wody.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    W zmiennych warunkach atmosferycznych warto zadbać o
                    odpowiednią izolację cieplną ciała. Istotne jest ubieranie
                    się stosownie do panujących warunków. Jeśli na dworze wieje,
                    pada deszcz lub słupek rtęci wskazuje ujemną temperaturę,
                    najlepiej założyć na siebie kilka warstw, ubrać się na tzw.
                    cebulkę, a gdy nam gorąco zdjąć jedną z nich, by zapobiec
                    przegrzaniu. &nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Wzmocnij swoją odporność lub dobierz odpowiednie leki na przeziębienie."
                    src="/web/kcfinder/uploads/images/shutterstock_1107896966.jpg"
                    style={{
                      width: "380px",
                      height: "243px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Dla odporności organizmu ważna jest również właściwa dieta -
                    zdrowa, bogata w witaminy, minerały, flawonoidy. Dzięki
                    warzywom i owocom (szczególnie pomarańczowym, czerwonym:
                    marchwi, papryce, dyni, pomidorom) dostarczamy
                    antyoksydantów oraz karotenoidów, bogatych w witaminę A i
                    zapobiegających rozwojowi chorób. Surówki i sałatki na bazie
                    kapusty to duża dawka witaminy C, ważna dla wzmocnienia
                    układu odpornościowego. Z kolei orzechy, kiełki czy olej
                    roślinny zawierające przeciwutleniacze, pomagają w walce
                    z&nbsp;infekcjami. Dieta musi być odpowiednio zbilansowana,
                    a posiłki jadane regularnie. To pozwoli na wzmocnienie{" "}
                    <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                      układu immunologicznego
                    </a>
                    . &nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Największym przeciwnikiem przeziębienia jest jednak
                    aktywność fizyczna, która kryje w sobie wiele zalet. Ruch,
                    świeże powietrze i wysiłek hartują organizm, dzięki czemu
                    zmniejsza się nasza podatność na zachorowanie. Dobra
                    kondycja fizyczna to również lepsze samopoczucie, wyższy
                    poziom endorfin i zdrowsze ciało.&nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    W profilaktyce przeziębienia należy też pamiętać o
                    wypoczynku. Sen to sprzymierzeniec zdrowia. Każdy z nas
                    powinien spać od 7 do 9 godzin dziennie, by organizm mógł
                    się zregenerować, wzmocnić i nabrać sił.&nbsp; &nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Statystycznie każdego z nas raz w roku dopada przeziębienie,
                    dlatego kiedy pojawi się infekcja, warto zadbać o
                    złagodzenie pierwszych objawów i zapobiec jej dalszemu
                    rozprzestrzenianiu się. Najlepiej zrobić to już w
                    początkowym etapie rozwoju infekcji.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Infekcja, której objawem jest katar może być spowodowane,
                    np. kontaktem z chorymi, przebywaniem w niewietrzonych
                    pomieszczeniach, osłabieniem organizmu, wahaniami
                    temperatury czy ubiorem niedostosowanym do pogody. W
                    sytuacji, gdy zmagamy się z zablokowanym nosem i zalegającą
                    w nim{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzieliną
                    </a>
                    , warto złagodzić te objawy, stosując leki odblokowujące
                    nos.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <strong>
                    <a href="/dobierz-lek/" target="_blank">
                      Zrób test i dobierz odpowiedni lek
                    </a>
                  </strong>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Dla osób, które preferują dyskretną formę aplikacji sprawdzą
                    się{" "}
                    <a href="/produkty/sudafed_tabletki/" target="_blank">
                      Sudafed<sup>®</sup> Tableki
                    </a>
                    , które dzięki zawartości{" "}
                    <a href="/slowniczek/#pseudoefedryna" target="_blank">
                      pseudoefedryny
                    </a>
                    , zmiejszają obrzęk{" "}
                    <a href="/slowniczek/#blona_sluzowa_nosa" target="_blank">
                      błony śluzowej nosa
                    </a>{" "}
                    oraz ilość nagromadzonej w górnych drogach oddechowych
                    wydzieliny, ułatwiając tym samym oddychanie i eliminując
                    uczucie ucisku głowy i zatok<sup>1</sup>. Z kolei dla osób,
                    które wolą miejscowe działanie odpowiednie będą areozole{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed<sup>®&nbsp;</sup>Xylospray
                    </a>{" "}
                    lub{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®&nbsp;</sup>Xylospray HA,
                    </a>
                    &nbsp; które dzięki zawartości{" "}
                    <a href="/slowniczek/#ksylometazolina" target="_blank">
                      ksylometazoliny
                    </a>
                    , szybko odblokowują nos.{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®&nbsp;</sup>Xylospray HA
                    </a>{" "}
                    zawiera dodatkowo trzy substancje nawilżające: glicerol,
                    sorbitol i kwas hialuronowy (w postaci soli sodowej) oraz
                    nie wywołuje podrażnień, gdyż nie zawiera
                    konserwantów.&nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Właściwa dieta, ruch i wypoczynek przygotują nasz organizm
                    na walkę z wirusami i wzmocnią układ immunologiczny, który
                    dzięki temu będzie mógł skuteczniej bronić się przed
                    infekcjami.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej
                    nosa.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Tylżanowska-Kisiel Joanna, <i>Przeziębienie i grypa</i>,
                        Helion, 2007.
                      </span>
                    </span>
                  </li>
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Górnicka Jadwiga,{" "}
                        <i>Nie daj się przeziębieniu! Receptury natury</i>, AWM
                        More
                        <a href="http://www.gandalf.com.pl/w/awm-morex/">
                          <span className="s7">x</span>
                        </a>
                        , 2014.
                      </span>
                    </span>
                  </li>
                  <li className="li3">
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        Dr n. med. Filip Mejza, Przeziebienie,{" "}
                        <i>Medycyna Praktyczna dla pacjentów</i>,&nbsp; [online]
                        http://grypa.mp.pl/przeziebienie/61668, przeziebienie.
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/domowe_sposoby_na_walke_przezie%CC%A8bieniem.png")',
                    }}
                  />
                  <span className="title">
                    Domowe sposoby na walkę z przeziębieniem
                  </span>
                </a>
                <a
                  href="/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/cztery_fazy_przezi_bienia.png")',
                    }}
                  />
                  <span className="title">
                    Cztery fazy rozwoju przeziębienia
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
